import { gql } from '@apollo/client/core';

export const BUSINESSES = gql`
  query businesses($capabilities: BusinessCapabilitiesInput, $search: String, $first: Int, $after: Int) {
    businesses(capabilities: $capabilities, search: $search, first: $first, after: $after) {
      nodes {
        id
        name
        legalName
        type
      }
      totalCount
    }
  }
`;

export const BUSINESS_BY_ID = gql`
  query getBusinessById($businessId: ID!) {
    getBusinessById(businessId: $businessId) {
      id
      name
      type
    }
  }
`;
