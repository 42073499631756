const { gql } = require('@apollo/client');

export const CONTACTSET_QUERY = gql`
  query contactSets($searchText: String, $worksAt: ID, $businessId: ID, $pagination: Pagination) {
    contactSets(searchText: $searchText, worksAt: $worksAt, businessId: $businessId, pagination: $pagination) {
      totalCount
      nodes {
        id
        firstName
        lastName
        officeName
        type
        gender
        emails {
          email
        }
        phones {
          number
          extension
          countryCode
        }
        worksAt {
          id
          name
          role
          worksWith {
            id
          }
        }
      }
    }
  }
`;

export const CONTACTSET_BY_ID_QUERY = gql`
  query contactSet($id: ID!) {
    contactSet(id: $id) {
      id
      firstName
      lastName
      officeName
      type
      gender
      note
      emails {
        email
      }
      phones {
        number
        countryCode
        extension
      }
      worksAt {
        id
        name
        role
        worksWith {
          id
          name
        }
      }
    }
  }
`;
