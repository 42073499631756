import { gql } from '@apollo/client';

export const CONTACTSET_CREATE_MUTATION = gql`
  mutation contactSetCreate($createParams: ContactSetInput!) {
    contactSetCreate(createParams: $createParams) {
      id
      firstName
      lastName
      officeName
      type
      gender
      note
      emails {
        email
      }
      phones {
        number
        extension
        countryCode
      }
      worksAt {
        id
        name
        role
      }
    }
  }
`;

export const CONTACTSET_PATCH_MUTATION = gql`
  mutation contactSetUpdate($id: ID!, $data: ContactSetInput!) {
    contactSetUpdate(id: $id, data: $data) {
      id
      firstName
      lastName
      officeName
      type
      gender
      note
      emails {
        email
      }
      phones {
        number
        extension
        type
      }
      worksAt {
        id
        name
        role
      }
    }
  }
`;

export const CONTACTSET_DELETE_MUTATION = gql`
  mutation contactSetDelete($id: ID!) {
    contactSetDelete(id: $id) {
      id
    }
  }
`;
