import { computed, getCurrentInstance, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

import { BUSINESSES, BUSINESS_BY_ID } from './query';

export function useAccumulativeBusinesses(variables, offset, after) {
  const root = getCurrentInstance().proxy;
  const { error } = useNotification();
  const accumulatedBusinesses = ref([]);
  const totalCount = ref(0);

  const queryVariables = computed(() => ({
    ...variables.value,
    first: offset,
    after: after?.value,
  }));

  const { result, loading, onError, refetch } = useQuery(BUSINESSES, queryVariables);

  watch(result, (newValue) => {
    if (newValue?.businesses?.nodes) {
      accumulatedBusinesses.value = [...accumulatedBusinesses.value, ...newValue.businesses.nodes];
    }
    totalCount.value = newValue?.businesses?.totalCount || 0;
  });

  onError((err) => {
    console.error('useBusinesses', err);
    error(root.$t('contactsSet.errors.business.fetch'));
  });

  const clearBusinesses = () => {
    accumulatedBusinesses.value = [];
  };

  return {
    businesses: accumulatedBusinesses,
    totalCount,
    clearBusinesses,
    loading,
    refetch,
  };
}

export function useBusinessById(variables) {
  const { result, loading, onError, refetch } = useQuery(BUSINESS_BY_ID, variables, () => ({
    enabled: !!variables.value.businessId,
    fetchPolicy: 'cache-first',
  }));

  const business = computed(() => result.value?.getBusinessById);

  return {
    business,
    loading,
    refetch,
    onError,
  };
}
