import { computed, getCurrentInstance, ref } from 'vue';

import { useNotification } from '@/modules/core';
import { apolloClient } from '@/plugins/apollo-client';
import { useMutation, useQuery } from '@vue/apollo-composable';

import { CONTACTSET_QUERY, CONTACTSET_BY_ID_QUERY } from './query';
import { CONTACTSET_CREATE_MUTATION, CONTACTSET_PATCH_MUTATION, CONTACTSET_DELETE_MUTATION } from './mutation';

export function useContactSets(variables, options) {
  const root = getCurrentInstance().proxy;
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(CONTACTSET_QUERY, variables, options);

  const contactSets = computed(() => result?.value?.contactSets.nodes ?? []);
  const totalCount = computed(() => result.value?.contactSets?.totalCount ?? 0);

  onError((err) => {
    console.error('useContactSets', err);
    error(root.$t('contactsSet.errors.contacts.fetch'));
  });

  return {
    contactSets,
    totalCount,
    loading,
    refetch,
  };
}

export function useContactSet() {
  const root = getCurrentInstance().proxy;
  const { error } = useNotification();
  const contactSet = ref();
  const loading = ref(false);

  const fetchContactSet = async (variables) => {
    try {
      loading.value = true;
      const { data } = await apolloClient.query({
        query: CONTACTSET_BY_ID_QUERY,
        variables,
      });

      contactSet.value = data.contactSet;
    } catch (err) {
      console.error('useContactSet', err);
      error(root.$t('contactsSet.errors.contacts.fetch'));
    } finally {
      loading.value = false;
    }
  };

  return {
    contactSet,
    loading,
    fetchContactSet,
  };
}

export function useContactSetCreate() {
  const root = getCurrentInstance().proxy;
  const { error } = useNotification();
  const errors = ref([]);

  const { mutate, loading, onDone, onError } = useMutation(CONTACTSET_CREATE_MUTATION, {
    update: (cache, { data: { contactSetCreate: newContactSet } }) => {
      cache.modify({
        fields: {
          contactSets(existingContactSets = { nodes: [] }) {
            return { nodes: [...existingContactSets.nodes, newContactSet] };
          },
        },
      });
    },
  });

  onError((err) => {
    const { graphQLErrors } = err;
    if (graphQLErrors.length) {
      for (const gqlError of graphQLErrors) {
        if (gqlError.extensions?.response?.status !== 409) {
          error(root.$t('contactsSet.errors.contacts.create'));
        } else {
          const conflicts = err.graphQLErrors?.map((e) => e?.extensions?.response).filter((r) => r?.status === 409);
          errors.value = conflicts?.map((conflict) => conflict?.body?.details?.existingChannels).pop();
          root.$message.error(root.$t('errors.action'));
        }
      }
    }
  });

  return {
    createContactSet: mutate,
    loading,
    onDone,
    errors,
  };
}

export function useContactSetPatch() {
  const root = getCurrentInstance().proxy;
  const { error } = useNotification();
  const errors = ref([]);

  const { mutate, loading, onDone, onError } = useMutation(CONTACTSET_PATCH_MUTATION, {
    update: (cache, { data: { contactSetUpdate: updatedContactSet } }) => {
      cache.modify({
        fields: {
          contactSets(existingContactSets = { nodes: [] }, { readField }) {
            return {
              nodes: existingContactSets.nodes.map((contactSet) => {
                if (readField('id', contactSet) === updatedContactSet.id) {
                  return updatedContactSet;
                }
                return contactSet;
              }),
            };
          },
        },
      });
    },
  });

  onError((err) => {
    const { graphQLErrors } = err;
    if (graphQLErrors.length) {
      for (const gqlError of graphQLErrors) {
        if (gqlError.extensions?.response?.status !== 409) {
          error(root.$t('contactsSet.errors.contacts.update'));
        } else {
          const conflicts = err.graphQLErrors?.map((e) => e?.extensions?.response).filter((r) => r?.status === 409);
          errors.value = conflicts?.map((conflict) => conflict?.body?.details?.existingChannels).pop();
          root.$message.error(root.$t('errors.action'));
        }
      }
    }
  });

  return {
    patchContactSet: mutate,
    loading,
    onDone,
    errors,
  };
}

export function useContactSetDelete() {
  const root = getCurrentInstance().proxy;
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(CONTACTSET_DELETE_MUTATION, {
    update: (cache, { data: { contactSetDelete: deletedContactSet } }) => {
      cache.modify({
        fields: {
          contactSets(existingContactSets = { nodes: [] }, { readField }) {
            return {
              nodes: existingContactSets.nodes.filter(
                (contactSet) => readField('id', contactSet) !== deletedContactSet.id
              ),
            };
          },
        },
      });
    },
  });

  onError((err) => {
    console.error('useContactSetDelete', err);
    error(root.$t('contactsSet.errors.contacts.delete'));
  });

  return {
    deleteContactSet: mutate,
    loading,
    onDone,
    onError,
  };
}
