<template>
  <DropdownTableFilter
    trigger="click"
    class="search-business-select"
    :hide-on-click="false"
    :filter-name="title"
    :clearable="false"
    :placement="$direction === 'rtl' ? 'bottom-end' : 'bottom-start'"
    @on-choose-item="onSelect"
  >
    <el-dropdown-menu slot="dropdown" class="search-business-select-dropdown">
      <el-input
        v-model="businessFilter.value"
        popper-class="search-filter-input"
        :placeholder="$t('contactsSet.actions.search')"
        :clearable="true"
        @input="handleSearchTextChange"
        @clear="handleSearchTextClear"
      >
        <template #prefix>
          <SearchIcon class="search-icon" />
        </template>
      </el-input>
      <el-divider />

      <div class="scrollable" @scroll="handleScroll">
        <el-dropdown-item v-for="business in businesses" :key="business.id + title" :command="business">
          <el-checkbox
            :key="`search-business-select-checkbox-${business.id}`"
            :label="business.name"
            :value="isSelected(business.id)"
            @change="onSelect(business)"
          >
          </el-checkbox>
        </el-dropdown-item>
      </div>
    </el-dropdown-menu>
  </DropdownTableFilter>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { debounce } from 'lodash';

import { DropdownTableFilter } from '@/modules/core';
import { SearchIcon } from '@/assets/icons';

import { useAccumulativeBusinesses } from '../../composition/businesses';

const SEARCH_DEBOUNCE_TIME = 500;

export default {
  name: 'SearchBusinessSelect',
  components: { DropdownTableFilter, SearchIcon },
  props: {
    title: { type: String, required: true },
    limit: { type: Number, required: false, default: 100 },
    selected: { type: Array, required: false, default: () => [] },
  },
  setup(props) {
    const businessFilter = reactive({
      value: '',
      debounced: '',
    });

    const after = ref(0);

    const { businesses, clearBusinesses, totalCount } = useAccumulativeBusinesses(
      computed(() => ({
        ...(businessFilter.debounced != '' && { search: businessFilter.debounced }),
        capabilities: props.capabilities,
      })),
      props.limit,
      after
    );

    watch(
      () => businessFilter.value,
      debounce((newValue) => {
        businessFilter.debounced = newValue;
      }, SEARCH_DEBOUNCE_TIME)
    );

    return { businesses, after, clearBusinesses, totalCount, businessFilter };
  },

  methods: {
    handleScroll(event) {
      const scrollTop = event.target.scrollTop;
      const scrollHeight = event.target.scrollHeight;
      const clientHeight = event.target.clientHeight;

      const bottomDistance = scrollHeight - scrollTop - clientHeight;
      if (bottomDistance === 0 && this.businesses.length !== this.totalCount) {
        this.after += 1;
      }
    },
    handleSearchTextChange(value) {
      this.businessFilter.value = value;
      this.clearBusinesses();
      this.after = 0;
    },
    handleSearchTextClear() {
      this.businessFilter.value = null;
      this.clearBusinesses();
      this.after = 0;
    },
    onSelect(business) {
      this.$emit('on-select', business);
    },
    isSelected(businessId) {
      return this.selected.some((business) => business.id === businessId);
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollable {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;

  & div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.search-icon {
  margin-top: 8px;
  width: 1rem;
  height: 1rem;
  margin-right: 4px;
}

.search-business-select-dropdown {
  max-height: 287px;
  overflow-y: auto;
  width: 359px;
}

.el-dropdown-menu {
  padding-top: 0px;
  padding-bottom: 0px;
}

.el-divider {
  margin: 0px;
}

:deep(.el-checkbox__label) {
  font-size: 14px;
  font-weight: 400;
}

:deep(input[popper-class='search-filter-input']) {
  border: transparent;
  height: 32px;
}
:deep(i.el-icon-circle-close.el-input__clear) {
  margin-top: -2px;
  width: 1rem;
  height: 1rem;
}
</style>
