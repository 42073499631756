<template>
  <div class="d-flex" :class="{ ['position-relative']: hover }" @mouseleave="hover = false" @mouseenter="hover = true">
    <p ref="text" class="w-100 text-truncate d-inline-block text-nowrap px-1 mx-n1" style="direction: ltr">
      {{ items.length ? items[0] : '-' }}
    </p>
    <p
      v-if="hover"
      class="d-inline-block start-0 text-nowrap px-1 mx-n1 bg-white shadow rounded position-absolute"
      style="z-index: 1; direction: ltr"
    >
      {{ items.length > 1 ? items.join(', ') : '' }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
